// fetch() API polyfill
// https://github.com/github/fetch
import 'whatwg-fetch'; 

// Promise() API polyfill
// https://github.com/taylorhakes/promise-polyfill
import Promise from 'promise-polyfill';
if (!window.Promise) {
  window.Promise = Promise;
}

/**
 * Setup our curved text for the header
 */
var archThis = document.getElementById('arch-text');
if ( archThis ) 
{
    const circleType = new CircleType(document.getElementById('arch-text'));
    circleType.radius(1000);
}

/**
 * Animations
 */
window.addEventListener('DOMContentLoaded', () =>
{
    window.setTimeout(() => {
        document.body.classList.add('go');
    }, 500);
});

/**
 * Form UI stuff here
 */
window.addEventListener('DOMContentLoaded', () =>
{
    const inputTypes = ['input', 'textarea', 'select'];

    document.addEventListener('focusin', evt => 
    {
        let self = evt.target;
        if ( inputTypes.indexOf(self.nodeName.toLowerCase()) === -1 ) { return; }
        self.parentElement.classList.add('focus','filled');
    });
    document.addEventListener('focusout', evt => 
    {
        let self = evt.target;
        if ( inputTypes.indexOf(self.nodeName.toLowerCase()) === -1 ) { return; }
        if ( self.value.trim() === '' ) {
            self.parentElement.classList.remove('filled');
        }
        self.parentElement.classList.remove('focus');
    });
});

/**
 * Form submission handler
 */
function formHandler(evt) 
{
    evt.preventDefault();

    const form   = evt.target;
    var formData = new FormData(form);
    var errors   = [];
    const btn    = document.getElementById('submit-btn');

    // Honeypot filled out?  
    if ( validation.isNotEmpty(formData.get('topyenoh').trim()) )
    {
        formErrorHandler(errors['topyenoh']);
        return false;
    }

    // Anything empty?
    errors = filterEmptyValues(formData, errors);
    if ( errors.length ) 
    {
        formErrorHandler(errors);
        return false;
    }

    // Correct value types?
    errors = filterValueTypes(formData, errors);
    if ( errors.length ) 
    {
        formErrorHandler(errors);
        return false;
    }

    btn.disabled = true;

    // Seems we're all set, so send it along
    fetch('./submission.php', {
        method: 'post',
        headers: new Headers({
            'X-CSRF-TOKEN': document.getElementById('_token').content,
            'X-REQUESTED-WITH': 'xmlhttprequest'
        }),
        body: new URLSearchParams(formData)
    })
        .then(function(response) {
            if ( response.ok ) { return response.json(); }
            else { throw new Error(response.json()); } 
        })
        .then(function(data) {
            // console.log('success', data);
            if ( data.errors ) 
            {
                // console.log(data.errors);
                btn.disabled = false;
                return;
            }
            window.location = '/thank-you';
        })
        .catch(function(error) {
            btn.disabled = false;
            // console.log('error', error);
            // failMsg.classList.add('visible');
            // submitBtn.innerHTML = 'Sign Up';
            // submitBtn.disabled = false;
        });
}
if ( document.getElementById('submission') )
{
    document.getElementById('submission').addEventListener('submit', formHandler);
}

/**
 * Check if any fields are empty and add them to the error array.  
 * 
 * @param  FormData Object
 * @param  Array
 * @return Array
 */
function filterEmptyValues(formData, errors)
{
    for ( var pair of formData.entries() )
    {
        if ( typeof pair === 'undefined' ) { continue; }
        if ( ! validation.isNotEmpty(pair[1].trim()) )
        {
            if ( pair[0] === 'topyenoh' ) { continue; }
            if ( pair[0] === 'graduated_from' ) { continue; }
            errors.push(pair[0]);
        }
    }
    return errors; 
}

/**
 * Use regex to validate field values.
 * 
 * @param  FormData Object
 * @param  Array
 * @return Array
 */
function filterValueTypes(formData, errors)
{
    for ( var pair of formData.entries() )
    {
        if ( typeof pair === 'undefined' ) { continue; }
        let elem = document.getElementById(pair[0]);
        let value = elem.value.trim();

        switch ( elem.type )
        {
            case 'text': 
            case 'textarea': 
            case 'select': 
            case 'select-one': break; // already check that it was not empty

            case 'email':
                if ( ! validation.isEmailAddress(value) ) { errors.push(pair[0]); }
                break;

            case 'tel':
                if ( ! validation.isPhoneNumber(value) ) { errors.push(pair[0]); }
                break;

            case 'number':
                if ( ! validation.isNumber(value) ) { errors.push(pair[0]); }
                break;
        }
    }
    return errors;
}

/**
 * Handle displaying any form errors to the user.
 * 
 * @param  Array
 * @return Void
 */
function formErrorHandler(errors)
{
    console.log(errors);
}

/**
 * Object of validation types for form input filtering.
 *
 * @type Object
 */
var validation = {
    isEmailAddress:function(str) {
        var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);  // returns a boolean
    },
    isNotEmpty:function (str) {
        var pattern =/\S+/;
        return pattern.test(str);  // returns a boolean
    },
    isNumber:function(str) {
        var pattern = /^\d+$/;
        return pattern.test(str);  // returns a boolean
    },
    isSame:function(str1,str2){
        return str1 === str2;
    },
    isPhoneNumber:function(str) {
        var pattern = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        return pattern.test(str);
    }
};   